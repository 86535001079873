import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import InputField from './InputField';
import {Theme} from './Theme';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Loading from './Loading';
import Toast from './Toast';
import YesNo from './YesNo';
import useStyles from './Styles';
import {UserContainer} from './UserContainer';
import {Inc,Profile,MaxProfileTitleLength,MaxProfileOwnerLength,MaxProfileMemoLength} from './Inc';

interface ConfigProps {
	isOpen:boolean,
	close(refresh:boolean):void,
}

const Config = (props:ConfigProps)=> {
    const [states,setStates] = useState({
        isSend:false,
        isLoading:false,
		isUpdated:false,
		isDelete:false,
		profile:Inc.defaultProfile(),
    });
	const [errors,setErrors] = useState(Inc.defaultProfileError());
    const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));
    const userState = UserContainer.useContainer();
	const classes = useStyles();

	const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close(false);
	}
	const openDelete = ()=>{
		setStates({...states,isDelete:true});
	}
	const closeDelete = ()=>{
		setStates({...states,isDelete:false});
	}
	const onChangeValue = (name:keyof Profile) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({...states,isUpdated:true,profile:{...states.profile,[name]:value}});
	};
	const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		console.log(event.target.value);
		let value = false;
		if( event.target.value==="true" ){
			value = true;
		}
		setStates({...states,isUpdated:true,profile:{...states.profile,status:value}});
	};
	const validate = ()=>{
		if( !userState.user ){
			return;
		}

		let err:boolean = false;
		let error = Inc.defaultProfileError();
		const title = states.profile.title.trim();
		if( title==="" ){
			error["title"] = "タイトルを入力してください";
			err = true;
		} else if( title.length>MaxProfileTitleLength ){
			error["title"] = "タイトルを"+MaxProfileTitleLength+"文字以内にしてください";
			err = true;
		}
		const owner = states.profile.owner.trim();
		if( owner==="" ){
			error["owner"] = "飼い主を入力してください";
			err = true;
		} else if( owner.length>MaxProfileOwnerLength ){
			error["owner"] = "飼い主を"+MaxProfileOwnerLength+"文字以内にしてください";
			err = true;
		}
		const memo = states.profile.memo.trim();
		if( memo.length>MaxProfileMemoLength ){
			error["memo"] = "紹介文を"+MaxProfileMemoLength+"文字以内にしてください";
			err = true;
		}

		if(err){
			setToast('赤字の項目を修正してください');
			setErrors(error);
			return false;
		}
		return true;
	}

	const load = async ()=>{
		if(!userState.user){
			return false;
		}
		try {
			await axios.get(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/profile`).then((response)=>{
				if( response.status===200 ){
					setStates({...states,profile:response.data,isLoading:false});
				}
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}		
	}
	const send = async ()=>{
		if(!userState.user){
			return false;
		}
		if( !validate() ){
			return false;
		}

		try {
			await axios.put(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/profile`, states.profile).then((response)=>{
				userState.setProfile(states.profile);
				props.close(false);
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}
	};
	const deleteProfile = async ()=>{
		if(!userState.user){
			return false;
		}
		try {
			await axios.delete(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/profile`).then((response)=>{
				userState.setProfile(Inc.defaultProfile());
				props.close(true);
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}
	}
	

	useEffect(()=>{
		if(!props.isOpen){
			setStates({
				isSend:false,
				isLoading:false,
				isUpdated:false,
				isDelete:false,
				profile:Inc.defaultProfile(),
			});
			setErrors(Inc.defaultProfileError());
			return;
		}
		setStates({...states,isLoading:true});
		load();
	},[props.isOpen]);

	if( !props.isOpen ){
		return (null);
	}

    return (
		<>
		<Dialog
			open={props.isOpen}
			onClose={close}
			fullScreen={fullScreen}
			aria-labelledby="editcat-dialog-title"
			aria-describedby="editcat-dialog-description"	
		>
			<DialogTitle style={{marginTop:0,marginBottom:0,color:'#03a9f4',fontWeight:'bold',fontSize:'140%'}}>
				公開設定
			</DialogTitle>
			<DialogContent dividers={true}>
				<Box style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
					・<span style={{color:'#03a9f4',fontWeight:'bold'}}>タイトル</span>と<span style={{color:'#03a9f4',fontWeight:'bold'}}>飼い主</span>を登録すると公開用のURLが発行されます<br/>
					・作成した年表をお友達に見せたい時は<span style={{color:'#03a9f4',fontWeight:'bold'}}>公開</span>を<span style={{color:'#03a9f4',fontWeight:'bold'}}>みんな</span>にしてください<br/>
				</Box>				
				<Grid container spacing={1} style={{marginTop:'2em',marginBottom:"0.5em"}}>
					<Grid item xs={12} sm={3} md={4}>
						<Typography variant="h6" style={{whiteSpace:'nowrap'}}>タイトル
						<span className={classes.formRequired}>必須</span>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
						<InputField label="タイトル" onChange={onChangeValue("title")} value={states.profile.title} error={errors.title} opt={{maxLength:MaxProfileTitleLength}}/>
						<FormHelperText>※{MaxProfileTitleLength}文字以内</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={3} md={4}>
						<Typography variant="h6" style={{whiteSpace:'nowrap'}}>飼い主
						<span className={classes.formRequired}>必須</span>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
						<InputField label="飼い主" onChange={onChangeValue("owner")} value={states.profile.owner} error={errors.owner} opt={{maxLength:MaxProfileOwnerLength}}/>
						<FormHelperText>※{MaxProfileOwnerLength}文字以内</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={3} md={4}>
						<Typography variant="h6" style={{whiteSpace:'nowrap'}}>紹介文
						</Typography>
					</Grid>
					<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
						<InputField label="紹介文" onChange={onChangeValue("memo")} value={states.profile.memo} error={errors.memo} opt={{maxLength:MaxProfileMemoLength,rows:8}}/>
						<FormHelperText>※{MaxProfileMemoLength}文字以内</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={3} md={4}>
						<Typography variant="h6" style={{whiteSpace:'nowrap'}}>公開
						</Typography>
					</Grid>
					<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
						<FormControl>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={states.profile.status}
								onChange={onChangeStatus}
							>
								<FormControlLabel value={true} control={<Radio color="secondary" />} label="みんな" />
								<FormControlLabel value={false} control={<Radio color="secondary" />} label="シークレット" />
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="error" onClick={openDelete}>削除</Button>
				<Box style={{flex:1}}/>
				<Button variant="text" color="inherit" onClick={close}>閉じる</Button>
				<Button variant="contained" color="primary" onClick={send}>決定</Button>
			</DialogActions>
		</Dialog>
		<Toast mes={toast} close={closeToast}/>
		<Loading isOpen={states.isLoading}/>
		<YesNo text="登録した内容をすべて削除してよろしいですか？（公開設定および年表のデータすべてが削除されます）" onYes={deleteProfile} onNo={closeDelete} isOpen={states.isDelete}/>
		</>
    );
}

export default Config;
