import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Title from './Title';
import SecurityIcon from '@mui/icons-material/Security';

interface PrivacyProps {

}

const Privacy = (props:PrivacyProps)=> {
	return (
		<Container maxWidth="md">
			<Title variant="h2" text="個人情報保護方針" icon={<SecurityIcon color="primary"/>}/>
			<Box style={{marginBottom:'1em'}}>
				有限会社レトロインク（以下、当社）は、当サービスのユーザー（以下、ユーザー）からお預かりした個人情報の重要性を認識し、その保護の徹底をはかりユーザーの信頼を得るために、個人情報に関する関係法令および規範を遵守するとともに、個人情報保護方針を定め確実な履行に努めることを宣言いたします。
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報保護方針の適用範囲"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>この個人情報保護方針は、当サービスを利用する際に適用されます。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の収集"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>当サービスへのユーザー登録にあたり、メールアドレスまたはSNSのアカウントが必要になります。</p>
				<p>利用者のIPアドレス、利用アプリケーション名、サーバーへのリクエストのログはサーバーに保存されます。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の公開"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>ユーザーの個人情報が第三者に公開されることはありません。ただし、公開設定の紹介文や年表の名前など公開を前提とした内容に個人情報が記載されている場合にはその限りではありません。</p>
				<p>登録内容はユーザーの選択により非公開にできますが、絶対的な秘密の保持を約束するものではありませんので、第三者に漏洩した場合に深刻な被害の予想されるような機密情報の登録は禁止します。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の利用目的"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>当社は以下に定める利用目的の達成に必要な範囲内でのみ個人情報を利用します。</p>
				<ul>
					<li>ユーザーの登録内容の確認、当サービスの運用に必要な通知、その他当サービスのサービス提供に係わること</li>
					<li>ユーザーからのご要望・ご意見および、その応対の品質向上のための対応履歴を確認すること</li>
					<li>当サービスの改善・開発のため、利用状況などを取得し分析すること</li>
				</ul>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の管理"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>個人情報の管理は当社だけが行い、当社の関連会社に対しても情報を供給することはいたしません。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の委託"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>当社の業務を外部に委託する際、委託先に個人情報を提供することはありません。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の第三者提供"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>ご提供いただいた個人情報は、以下のいずれかに該当する場合を除き、第三者に対して開示いたしません。</p>
				<ul>
					<li>ユーザー本人の同意がある場合</li>
					<li>人の生命、身体または財産の保護のために必要な場合であって、ユーザー本人の同意を得ることが困難な場合</li>
					<li>法令に基づき開示することが必要である場合および裁判所、警察等公的機関から開示を求められた場合</li>
				</ul>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の取扱いについて"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>当社は、ユーザーの個人情報を管理する際は管理責任者をおき、適切な管理を行うとともに、ユーザーの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破損、改ざん、漏洩などを防止するため、セキュリティシステムの維持、社内管理体制、システムの整備、社員教育の徹底等必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。</p>
				<p>また、個人情報に係るデータベース等へのアクセスについてはアクセス権を有する者を限定し、社内においても不正な利用がおこらないように厳重に管理します。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の残存期間"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>収集した個人情報は原則として以下の範囲で保持します。ただし、弊社の破綻・担当者の不慮の死去などやむをえない事情により期間を短縮する場合があります。</p>
				<ul>
					<li>当サービスへのIPアドレスを含むすべてのリクエストのログを90日間保持します。</li>
					<li>ユーザーのメールアドレスおよびIPアドレスを12ヵ月間保持します。</li>
				</ul>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報の開示等の請求"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>ユーザー本人または代理人は利用目的の通知・開示、内容の訂正・追加・削除、利用の停止・消去及び第三者への提供の停止（以下「開示等」といいます）を求めることができます。</p>
				<ul>
					<li>開示等の請求を受けた場合は、当社の規定に従ってすみやかに対応します。</li>
					<li>開示等の手続きにあたり、ご本人であることまたは代理人であることの確認をします。</li>
					<li>請求方法はお問い合わせフォームよりお問い合わせください。</li>
				</ul>
				<p>以下のいずれかに該当する場合、開示は差し控え、理由を付記して通知します。</p>
				<ul>
					<li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
					<li>業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
					<li>法令に違反することとなる場合</li>
				</ul>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="ユーザーの責任について"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>当サービスの利用は、ユーザーの責任において行われるものとし、日本国の法律の条例に拘束されることに同意するものとします。</p>
				<p>また、日本以外の国・地域においては異なる法的要件が適用される場合があります。</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="個人情報保護管理者"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>有限会社レトロインク<br/>代表取締役 石村寛之</p>
			</Box>
			<Box style={{borderBottom:'1px solid #333333',marginBottom:'1em'}}>
				<Title variant="h3" text="この個人情報保護方針の改定について"/>
			</Box>
			<Box style={{marginBottom:'1em'}}>
				<p>今後、当社は個人情報保護方針の全部または一部を改定することがあります。重要な変更がある場合には、当サービス上において、分かりやすい方法でお知らせいたします。 			</p>
			</Box>
			<Box style={{marginTop:'2em',marginBottom:'6em',textAlign:'right'}}>
				2023.03.31<br/>
				有限会社レトロインク<br/>
			</Box>
		</Container>
	);
}

export default Privacy;
