import React, {useState,useEffect} from 'react';
import {Link,useLocation,matchPath} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TagIcon from '@mui/icons-material/Tag';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ListIcon from '@mui/icons-material/List';
import CakeIcon from '@mui/icons-material/Cake';
import PetsIcon from '@mui/icons-material/Pets';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ForwardIcon from '@mui/icons-material/Forward';
import CopyToClipboard from 'react-copy-to-clipboard';
import {UserContainer} from './UserContainer';
import Toast from './Toast';
import useStyles from './Styles';

interface BottomMenusProps {
	mode: "chart" | "list",
	toggleMode():void,
	toggleStart?():void,
	openZoom?():void,
	openTag?():void,
	selectedTag?: string,
	zoom: number,
	start: string,
}

const BottomMenus = (props:BottomMenusProps)=> {
	const [states, setStates] = useState({
		index:0,
		isEditCat: false,
	});
	const [toast,setToast] = useState('');
	//const navigate = useNavigate();
	const classes = useStyles();
	const userState = UserContainer.useContainer();
	const { pathname } = useLocation();
	const navs = ["/","/list","/edit","/login"];

	//const closeEditCat = ()=>{
	//	setStates({...states,isEditCat:false});
	//}

	const handleClickShowPassword = () => {

	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const toggleMode = ()=>{
		props.toggleMode();
	}

	const toggleStart = ()=>{
		if( props.toggleStart ){
			props.toggleStart();
		}
	}

	const openZoom = ()=>{
		if( props.openZoom ){
			props.openZoom();
		}
	}

	const openTag = ()=>{
		if( props.openTag ){
			props.openTag();
		}
	}

	const closeToast = ()=>{
		setToast('');
	}

	const copy = ()=>{
		setToast("公開ページのURLをクリップボードにコピーしました");
	}

	useEffect(()=>{
		let index = 0;
		navs.forEach((path:string)=>{
			if(matchPath(path, pathname)){
				setStates({...states,index:index});
			}
			index++;
		});
	},[pathname]);

	if(!userState.user){
		return (null);
	}

	let url = '';
	if( userState.profile.code!=='' ){
		url = process.env.REACT_APP_VIEW_URL+userState.profile.code;
	}

	return (
		<Box style={{position:'fixed',bottom:'0em',right:'0',padding:'0',width:'100%',backgroundColor:'white',borderBottom:'12px solid #03a9f4'}}>
			<Container maxWidth="lg">
				<Grid container spacing={1} direction="row-reverse">
					<Grid item xs={5} sm={4} md={2} lg={2}>
						<FormControl fullWidth={true} style={{paddingTop:'0.4em'}}>
							<Button component={Link} to={url} disabled={url===''} target="_blank" size="large" variant="outlined" color="primary" sx={{whiteSpace:'nowrap',height:'40px',fontSize:{xs:'80%',sm:'90%'}}} startIcon={<ForwardIcon/>}>公開ページ</Button>
						</FormControl>
					</Grid>
					<Grid item xs={7} sm={8} md={4} lg={5}>
						<FormControl fullWidth={true} style={{paddingTop:'0.4em'}}>
							<TextField
								variant="outlined"
								size="small"
								value={url}
								sx={{backgroundColor:'#EEEEEE',height:'40px'}}
								disabled={url===''}
								InputProps={{
									endAdornment:(
									<InputAdornment position="end">
										<CopyToClipboard text={url} onCopy={copy}>
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												<ContentCopyIcon/>
											</IconButton>
										</CopyToClipboard>
									</InputAdornment>
									),
								}}		
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={5} sx={{paddingRight:{xs:'0em',sm:'0em',md:'4em',lg:'8em'}}}>
						<Stack direction="row" spacing={0}>
							<IconButton color="inherit" className={classes.iconButtonLabel} sx={{width:'25%'}} onClick={toggleMode}>{props.mode==='list' ? <AssessmentIcon style={{transform:'rotate(90deg)'}}/> : <ListIcon/>}<Box className={classes.iconButtonLabelText}>{props.mode==='list' ? "年表" : "リスト"}</Box></IconButton>
							<IconButton color="inherit" disabled={props.mode==='list'} className={classes.iconButtonLabel} sx={{width:'25%'}} onClick={toggleStart}>{props.start==='birth' ? <PetsIcon/> : <CakeIcon/>}<Box className={classes.iconButtonLabelText}>{props.start==='birth' ? "来た日" : "誕生日"}</Box></IconButton>
							<IconButton color="inherit" disabled={props.mode==='list'} className={classes.iconButtonLabel} sx={{width:'25%'}} onClick={openZoom}><ZoomInIcon/><Box className={classes.iconButtonLabelText}>{`${props.zoom}%`}</Box></IconButton>
							<IconButton color={(props.selectedTag && props.selectedTag!=='') ? "primary":"inherit"} className={classes.iconButtonLabel} sx={{width:'25%'}} onClick={openTag}><TagIcon/><Box className={classes.iconButtonLabelText}>タグ</Box></IconButton>
						</Stack>
					</Grid>
				</Grid>
			</Container>
			<Toast mes={toast} close={closeToast}/>
		</Box>
	);
}

export default BottomMenus;
