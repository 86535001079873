import React,{useState,useEffect} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import PetsIcon from '@mui/icons-material/Pets';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {UserContainer} from './UserContainer';
import Title from './Title';

interface HelloProps {
}

const Hello = (props:HelloProps)=> {
	const userState = UserContainer.useContainer();
	const [step,setStep] = useState(1);
	useEffect(()=>{
		if( userState.ready ){
			if( userState.user?.uid ){
				setStep(2);
			} else {
				setStep(1);
			}
		} else {
			setStep(1);
		}
	},[userState.ready,userState.user?.uid])
	return (
		<Container maxWidth="md">
		{step===1 &&
			<Box sx={{border:'1px solid #b3e5fc',backgroundColor:'#e1f5fe',color:'#444444',padding:'2em',paddingBottom:'4em',fontSize:'100%',lineHeight:'1.8em'}}>
				<Title variant="h2" text="STEP.1　ログイン"/>
				年表作成のネコネンへようこそ！<br/>
				まずは右上の<AccountCircleIcon color="primary" style={{verticalAlign:'middle',fontSize:'200%'}}/>でログインをしてください<br/>
			</Box>
		}
		{step===2 &&
			<Box sx={{border:'1px solid #b3e5fc',backgroundColor:'#e1f5fe',color:'#444444',padding:'2em',paddingBottom:'4em',fontSize:'100%',lineHeight:'1.8em'}}>
				<Title variant="h2" text="STEP.2　猫を登録"/>
				あとは同じく右上の<AddIcon color="primary" style={{verticalAlign:'middle',fontSize:'200%'}}/>で猫を登録するだけです<br/>
				簡単ですね！<br/>
			</Box>
		}
		</Container>
	);
}

export default Hello;
