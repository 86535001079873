import React,{useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from './Theme';
import Toast from './Toast';
import BottomMenus from './BottomMenu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from './useWindowSize';
import {Inc,Cat} from './Inc';

interface CatChartProps {
	cats: Cat[],
	tags: string[],
	edit(id:number):void,
	onClick():void,
}

const CatChart = (props:CatChartProps)=> {
	const catHeight = 70;
	const paddingTop = 20;
	const paddingBottom = 0;
	const paddingLeft = 20;
	const paddingRight = 20;
	const canvasHeight = catHeight * props.cats.length + paddingTop + paddingBottom;
	const isSmall = useMediaQuery(Theme.breakpoints.down('sm'));
	const [width, height] = useWindowSize();

	const [states,setStates] = useState({
		zoom: 100,
		start: 'start',
		toast: '',
		isZoom: false,
		isTag: false,
		selectedTag: '',
	})

	const edit = (index:number)=>{
		props.edit(props.cats[index].id);
	}

	const calcDays = (from:string,to:string) =>{
		const day1 = new Date(from).getTime();
		const day2 = new Date(to).getTime();
 		return (day2 - day1) / 86400000;
	}

	function valueLabelFormat(value: number) {
		return `${value}%`;
	}

	const onChangeZoom = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			//setZoom(newValue);
			setStates({...states,zoom:newValue});
		}
	};

	const zero = (num:number) =>{
		if( num<10 ){
			return ("0"+num).substring(-2);
		} else {
			return String(num);
		}
	}

	const toggleStart = ()=>{
		if( states.start==='start' ){
			//setStart('birth');
			setStates({...states,start:'birth',toast:'誕生日からの表示に切り替えました'});
		} else {
			//setStart('start');
			setStates({...states,start:'start',toast:'来た日からの表示に切り替えました'});
		}
	}

	const closeToast = ()=>{
		setStates({...states,toast:''});
	}

	const openZoom = ()=>{
		setStates({...states, isZoom:true});
	}

	const openTag = ()=>{
		setStates({...states, isTag:true});
	}

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if ( event && event.type === 'keydown' &&
		((event as React.KeyboardEvent).key === 'Tab' ||
		(event as React.KeyboardEvent).key === 'Shift')
      ) {
		return;
      }
	  if( !open ){
      	setStates({...states, isZoom:false, isTag:false});
	  }
    };

	const selectTag = (tag:string)=>{
		if( states.selectedTag===tag ){
			tag = '';
		}
		setStates({...states,selectedTag:tag});
	}

//	const chart = useMemo(()=>{
//		console.log("render");

		const today = new Date().getFullYear()+"-"+zero(new Date().getMonth()+1)+"-"+new Date().getDate();
		const scrollHeight = height-240;

		let maxMeters = 14;
		if(isSmall){
			maxMeters = 8;
		}
		maxMeters = Math.floor(maxMeters*states.zoom/100);
	
		let minDate = today;
		let maxDate = today;
	
		for( const index in props.cats ){
			if( states.start==='start' ){
				if( props.cats[index].start && props.cats[index].start<minDate ){
					minDate = props.cats[index].start;
				}
			} else {
				if( props.cats[index].birth && props.cats[index].birth<minDate ){
					minDate = props.cats[index].birth;
				}
			}
			if( props.cats[index].end && props.cats[index].end>maxDate ){
				maxDate = props.cats[index].end;
			}
		}
	
		const fullMonths = (parseInt(maxDate.substring(0,4)) - parseInt(minDate.substring(0,4))) * 12 - parseInt(minDate.substring(5,7)) + 1 + parseInt(maxDate.substring(5,7));
		let perMeters = 1;
		if( maxMeters<fullMonths ){
			perMeters = Math.floor(fullMonths/maxMeters);
		}
	
		let y = parseInt(minDate.substring(0,4));
		let m = parseInt(minDate.substring(5,7))
		let meters = [];
		if( fullMonths>1 ){
			for( let i=0; i<fullMonths; i++ ){
				if( i%perMeters===0 ){
					meters.push({
						index: i,
						y:y,
						m:zero(m),
					});
				}
				m++;
				if( m>12 ){
					y++;
					m=1;
				}
			}
		}

		//const [alignment, setAlignment] = React.useState<string | null>('left');

		//const handleAlignment = (
		//  event: React.MouseEvent<HTMLElement>,
		//  newAlignment: string | null,
		//) => {
		//  setAlignment(newAlignment);
		//};		
	
		const fullDays = calcDays(minDate,maxDate);
		const percentPerDay = 100 / fullDays;
		const percentPerMonth = 100 / fullMonths;

		//console.log("cat chart rendering");

		return (
			<Box style={{width:`${states.zoom}%`,margin:'0 auto',boxSizing:'border-box',paddingTop:`${paddingTop}px`,paddingBottom:`${paddingBottom}px`,paddingLeft:`${paddingLeft}px`,paddingRight:`${paddingRight}px`}}>
				<Box sx={{position:'relative',height:'5em',fontSize:{xs:'60%',md:'70%'}}}>
				{meters.map((data:any,index:number)=>{
					const key = "month"+index;
					const x = percentPerMonth*data.index;
					return (
						<Box key={key} style={{position:'absolute',top:'0',left:`${x}%`,transform:"translate(-50%,0%)",color:'#333366',textAlign:'center'}}>{data.y}<br/>{data.m}</Box>
					)
				})}
				</Box>
				<Box style={{width:'100%',height:`${scrollHeight}px`,position:'relative'}}>
				{meters.length>0 && meters.map((data:any,index:number)=>{
					const key = "line"+index;
					const x = percentPerMonth*data.index;
					return (
						<Box key={key} style={{width:'1px',height:`${canvasHeight}px`,position:'absolute',top:'-1em',left:`${x}%`,backgroundColor:'#EEEEEE'}}></Box>
					)
				})}
				{props.cats.length>0 && props.cats.map((cat:Cat,index:number)=>{
					if( states.selectedTag!=='' ){
						if(! cat.tags.includes(states.selectedTag) ){
							return (null);
						}
					}

					const key = "cat"+index;
					let days = 0;
					let kijun = '';
					if( states.start==='start' ){
						if( cat.start!=='' ){
							kijun = cat.start;
							if( cat.end!=='' ){
								days = calcDays(cat.start,cat.end);
							} else {
								days = calcDays(cat.start,today);
							}
						}
					} else {
						if( cat.birth!=='' ){
							kijun = cat.birth;
							if( cat.end!=='' ){
								days = calcDays(cat.birth,cat.end);
							} else {
								days = calcDays(cat.birth,today);
							}
						}
					}
					if( days===0 ){
						return (null);
					}
					const w = Math.floor(days * percentPerDay*100)/100;
					const x = Math.floor(calcDays(minDate,kijun)*percentPerDay*100)/100;
					let color = '#b3e5fc';
					let end_color = '#81d4fa';
					if(states.start==='birth'){
						color = '#b2ebf2';
						end_color = '#80deea';
					}
					return (
						<Box key={key} style={{position:'relative',height:`${catHeight}px`}}>
							<Box style={{borderBottom:`1px solid ${end_color}`,borderTop:`1px solid ${end_color}`,backgroundColor:color,height:'2em',width:`${w}%`,marginLeft:`${x}%`,borderRadius:'0.25em',position:'relative'}}>
							{cat.end!=='' ? (
								<Box style={{background:`linear-gradient(90deg, ${color}, #FFFFFF)`,height:'2em',width:`8px`,right:'-4px',top:'0',borderRadius:'0 0.25em 0.25em 0',position:'absolute'}}></Box>
							):(
								<Box style={{backgroundColor:end_color,height:'2em',width:`10px`,right:'0',top:'0',borderRadius:'0 0.25em 0.25em 0',position:'absolute'}}></Box>
							)}
							{cat.img!=='' ? (
								<Avatar src={Inc.getUploadImageURL(cat.img)} style={{position:'absolute',top:'-3px',left:'-20px',cursor:'pointer'}} onClick={()=>edit(index)}/>
							):(
								<Avatar style={{position:'absolute',top:'-3px',left:'-20px',cursor:'pointer'}} onClick={()=>edit(index)}><FontAwesomeIcon icon={faPaw}/></Avatar>
							)}
							</Box>
							<Box style={{position:'absolute',left:`${x}%`,top:'3em',fontSize:'80%',color:'#333333',transform : "translate(-50%,0%)",whiteSpace:'nowrap'}}>{cat.name}</Box>
						</Box>
					)
				})}
				</Box>
				<BottomMenus mode="chart" start={states.start} zoom={states.zoom} toggleStart={toggleStart} toggleMode={props.onClick} openZoom={openZoom} openTag={openTag} selectedTag={states.selectedTag}/>
				<Toast mes={states.toast} close={closeToast}/>
				<SwipeableDrawer
					anchor="bottom"
					open={states.isZoom || states.isTag}
					onClose={toggleDrawer(false)}
					onOpen={toggleDrawer(true)}
				>
					<Container maxWidth="md">
					{states.isZoom &&
						<Stack direction='row' spacing={2} alignItems="center" style={{padding:'3em 0em'}}>
							<ZoomOutIcon/>
							<Slider
								defaultValue={states.zoom}
								aria-label="Small"
								valueLabelDisplay="auto"
								min={10}
								max={300}
								step={10}
								marks
								valueLabelFormat={valueLabelFormat}
								onChange={onChangeZoom}
							/>
							<ZoomInIcon/>
						</Stack>
					}
					{states.isTag &&
						<Box style={{padding:'2em 0em',textAlign:'center'}}>
							{props.tags.map((tag:string,index:number)=>{
								const key = "tag"+index;
								return (
									<Button variant={states.selectedTag===tag ? "contained":"outlined"} color={states.selectedTag===tag ? "primary":"inherit"} key={key} style={{margin:'0.5em'}} onClick={()=>selectTag(tag)}>{tag}</Button>
								)
							})}
							{props.tags.length===0 && <Box>タグは設定されていません</Box>}
						</Box>
					}
					</Container>
				</SwipeableDrawer>				
			</Box>
		);
	//},[isSmall,height,canvasHeight,states.zoom,states.start]);

	//return chart;
}

export default CatChart;
