import React,{useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Top from './Top';
import About from './About';
import Rule from './Rule';
import Privacy from './Privacy';
import Login from './Login';
import Config from './Config';
import EditCat from './EditCat';
import NoService from './NoService';
import Oldversion from './Oldversion';
//import useStyles from './Styles';
import {Theme} from './Theme';
import MailNew from './MailNew';
import Header from './Header';
import {UserContainer} from './UserContainer';

function App() {
	//const classes = useStyles();
	const [states,setStates] = useState({
		editCatId: 0,
		isLoading: true,
		isLogin: false,
		isEditCat: false,
		isConfig: false,
		ver: 0,
		verNewer: "",
		onService: true,
	});
	//const navigate = useNavigate();
	const basename = process.env.REACT_APP_BASENAME;

	const handleOldClose = ()=>{
		//setStates({...states,isOld:false});
	}

	const openLogin = ()=>{
		setStates({...states,isLogin:true});
	}

	const closeLogin = (refresh:boolean)=>{
		if( refresh ){
			setStates({...states,isLogin:false,ver:states.ver + 1});
		} else {
			setStates({...states,isLogin:false});
		}
	}

	const openEditCat = (id:number)=>{
		setStates({...states,editCatId:id,isEditCat:true});
	}

	const closeEditCat = (updated:boolean)=>{
		let ver = states.ver;
		if( updated ){
			ver++;
		}
		setStates({...states,isEditCat:false,ver:ver});
	}

	const openConfig = ()=>{
		setStates({...states,isConfig:true});
	}

	const closeConfig = (refresh:boolean)=>{
		if( refresh ){
			setStates({...states,isConfig:false,ver:states.ver + 1});
		} else {
			setStates({...states,isConfig:false});
		}
	}

	return (
		<UserContainer.Provider>
			<BrowserRouter basename={basename}>
				<ThemeProvider theme={Theme}>
					<Header openLogin={openLogin} openEditCat={openEditCat} openConfig={openConfig}/>
					<Box>
						<Routes>
							<Route path="/" element={<Top openEditCat={openEditCat} ver={states.ver}/>}/>
							<Route path="/about" element={<About/>}/>
							<Route path="/rule" element={<Rule/>}/>
							<Route path="/privacy" element={<Privacy/>}/>
							<Route path='/mail/new' element={<MailNew/>}/>
						</Routes>
					</Box>
					<Login isOpen={states.isLogin} close={closeLogin}/>
					<Config isOpen={states.isConfig} close={closeConfig}/>
					<EditCat isOpen={states.isEditCat} catId={states.editCatId} close={closeEditCat}/>
					<Oldversion close={handleOldClose} latest={states.verNewer} isOpen={states.verNewer!==""}/>
					<NoService isOpen={!states.onService}/>
				</ThemeProvider>
			</BrowserRouter>
		</UserContainer.Provider>
	);
}

export default App;
