import { createTheme } from '@mui/material/styles';
import {blueGrey, grey, teal, red, orange, pink, lightBlue} from '@mui/material/colors';

export const Theme = createTheme({
	palette: {
		primary: {...lightBlue,contrastText:'#FFFFFF'},
		secondary: teal,
		error: red,
		warning: orange,
		info: blueGrey,
		success: pink,
		background: {
			default: '#fff',
			paper: '#fff',
		},
		text: {
			primary: grey[900],
			secondary: grey[800],
		},		
	},
	//palette: {
	//	primary: {
	//		light: '#534bae',
	//		main: '#1a237e',
	//		dark: '#000051',
	//		contrastText: '#ffffff',
	//	},
	//	secondary: {
	//		light: '#FF9A00',
	//		main: '#FF8A00',
	//		dark: '#EF7A00',
	//		contrastText: '#444444',
	//	},
	//	error: red,
	//	warning: deepOrange,
	//	info: blueGrey,
	//	success: teal,
	//	//divider: grey[200],
	//	//border: grey[900],//?
	//	background: {
	//		//default: grey[50],
	//		//paper: grey[50],
	//		default: '#fff',
	//		paper: '#fff',
	//	},
	//	text: {
	//		primary: grey[900],
	//		secondary: grey[800],
	//	},
	//},
});


//export const Theme = createTheme({
    //typography: {
    //    useNextVariants: true,
    //},
    //palette: {
    //    primary: {
    //        light: '#534bae',
    //        main: '#1a237e',
    //        dark: '#000051',
    //        contrastText: '#ffffff',
    //    },
    //    secondary: {
    //        light: '#FF9A00',
    //        main: '#FF8A00',
    //        dark: '#EF7A00',
    //        contrastText: '#444444',
    //    },
    //},
    //overrides: {
    //    MuiFormLabel: {
    //        root: {
    //            color: "#1a237e",
    //        }
    //    },
    //},
//});
