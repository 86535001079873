import React, { useState,useEffect } from 'react';
import Container from '@mui/material/Container';
import axios from "axios";
import Hello from './Hello';
import CatList from './CatList';
import CatChart from './CatChart';
import {UserContainer} from './UserContainer';
import {Cat,ImageData} from './Inc';

interface TopProps {
	ver: number,
	openEditCat(id:number):void,
}

const Top = (props:TopProps)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		cats: [] as Cat[],
		tags: [] as string[],
		mode: "chart" as "list" | "chart" | "hello",
		image: {} as ImageData,
		isLoading: true,
	});

	const load = async (first:boolean)=>{
		if(!userState.user){
			return false;
		}
		let url = `${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/cats`;
		if( first ){
			url = `${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/profile/cats`;
		}

		try {
			await axios.get(url).then((response)=>{
				if( response.status===200 ){
					let tags = [] as string[];
					for( const index1 in response.data.cats ){
						for( const index2 in response.data.cats[index1].tags ){
							if(!tags.includes(response.data.cats[index1].tags[index2])){
								tags.push(response.data.cats[index1].tags[index2]);
							}
						}
					}		
					if( response.data.cats && response.data.cats.length>0 ){
						let mode = states.mode;
						if( mode==="hello" ){
							mode = "chart";
						}
						setStates({...states,mode:mode,cats:response.data.cats,tags:tags});
					} else {
						setStates({...states,mode:'hello'});
					}
					if( response.data.profile ){
						userState.setProfile(response.data.profile);
					}
				}
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}
	}

	//const loadFirst = async ()=>{
	//	if(!userState.user){
	//		return false;
	//	}
	//	try {
	//		await axios.get(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/profile/cats`).then((response)=>{
	//			if( response.status===200 ){
	//				let tags = [] as string[];
	//				for( const index1 in response.data.cats ){
	//					for( const index2 in response.data.cats[index1].tags ){
	//						if(!tags.includes(response.data.cats[index1].tags[index2])){
	//							tags.push(response.data.cats[index1].tags[index2]);
	//						}
	//					}
	//				}
	//				setStates({...states,cats:response.data.cats,tags:tags});
	//				if( response.data.profile ){
	//					userState.setProfile(response.data.profile);
	//				}
	//			}
	//		}).catch((e)=>{
	//			console.log(e);
	//		});
	//	} catch(err) {
	//		console.log(err);
	//	}
	//}

	const edit = (id:number)=>{
		props.openEditCat(id);
	}

	const toggleMode = ()=>{
		if( states.mode==='list' ){
			setStates({...states,mode:'chart'});
		} else {
			setStates({...states,mode:'list'});
		}
	}

	useEffect(()=>{
		//console.log("top_effect");
		if(userState.ready){
			//console.log("top_ready");
			if( !userState.user ){
				//console.log("top_no_uid");
				setStates({
					cats: [],
					tags: [],
					mode: "hello",
					image: {} as ImageData,
					isLoading: true,
				});
			} else if( userState.profile.code!=='' ){
				//console.log("top_load");
				load(false);
			} else {
				//console.log("top_load_first");
				load(true);
			}
		} else {
			//console.log("top_no_ready");
		}
	},[userState.ready,userState.user?.uid,props.ver]);

	//console.log("top rendering");

	return (
		<Container maxWidth="lg" style={{overflowX:'auto'}}>
			{states.mode==='chart' && <CatChart cats={states.cats} tags={states.tags} edit={edit} onClick={toggleMode}/>}
			{states.mode==='list' && <CatList cats={states.cats} tags={states.tags} edit={edit} onClick={toggleMode}/>}
			{states.mode==='hello' && <Hello/>}
		</Container>
	);
}

export default Top;
