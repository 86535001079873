import React, { useState,useEffect,useCallback } from "react";
//import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import CircularProgress from '@mui/material/CircularProgress';
//import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import UploadFileIcon from '@mui/icons-material/Image';
//import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from '@mui/material/Slider';
import Cropper from 'react-easy-crop'
//import ReactCrop,{Crop} from 'react-image-crop'
//import useStyles from './Styles';
import getCroppedImg from './getCroppedImg';
//import ImagePager from "./ImagePager";
//import {ImageData} from './Inc';
//import {Theme} from './Theme';

interface ImageUploadProps {
	isOpen: boolean,
	src: string,
	width: number,
	height: number,
	update: (src:string) => void,
	close():void,
}

const ImageTrimming: React.FC<ImageUploadProps> =  (props)=> {
	//const classes = useStyles();
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [size, setSize] = useState({w:0,h:0});
	const [zoom, setZoom] = useState(100);
	const [croppedAreaPixels,setCroppedAreaPixels] = useState();
  
	const onCropComplete = useCallback((croppedArea:any, croppedAreaPixels:any) => {
	  //console.log(croppedArea, croppedAreaPixels)
	  //console.log("onCropComplete");
	  //console.log(crop);
	  //console.log(croppedAreaPixels);
	  setCroppedAreaPixels(croppedAreaPixels);
	}, [])
  
	const showCroppedImage = useCallback(async () => {
		if (!croppedAreaPixels) return;
		try {
			const croppedImage = await getCroppedImg(props.src, croppedAreaPixels);
			props.update(croppedImage);
			//setCroppedImgSrc(croppedImage);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels, props.src]);

	const close = ()=>{
		props.close();
	}

	function valueLabelFormat(value: number) {
		return `${value}%`;
	}

	const onChangeZoom = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			setZoom(newValue);
		}
	};


	useEffect(()=>{
		if( props.isOpen ){
			const image = new Image();
			image.src = props.src;
			const w = image.width;
			const h = image.height;
			let z = 1;
			if( w>h ){
				z = props.height/h;
			} else {
				z = props.width/w;
			}
			setZoom(Math.floor(z*1000)/10);
			setSize({w:w,h:h});
			setCrop({x:0,y:0});
		} else {
			//
			setZoom(100);
			setCrop({x:0,y:0});
		}
	},[props.isOpen]);


  	return (
		<Dialog
			open={props.isOpen}
			onClose={close}
			fullScreen={true}
			aria-labelledby="trimming-dialog-title"
			aria-describedby="trimming-dialog-description"
			style={{minHeight:`${size.h}px`}}
		>
			<DialogTitle style={{textAlign:'center',padding:'0.5em 0',zIndex:9000,backgroundColor:'white',color:'teal'}}>
				画像のトリミング
			</DialogTitle>
			<DialogContent>
				<Cropper
					image={props.src}
					crop={crop}
					zoom={zoom/100}
					cropSize={{
						width: props.width,
						height: props.height,
					}}
					aspect={1}
					showGrid={false}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
				/>
			</DialogContent>
			<Box style={{textAlign:'center',padding:'1em 0',zIndex:9000,backgroundColor:'white'}}>
				<Box style={{maxWidth:'400px',width:'100%',margin:'0 auto',marginBottom:'1.5em'}}>
				<Slider
					defaultValue={100}
					value={zoom}
					aria-label="Small"
					valueLabelDisplay="auto"
					min={10}
					max={300}
					step={0.1}
					valueLabelFormat={valueLabelFormat}
					onChange={onChangeZoom}
				/>
				</Box>
				<Button variant="text" color="inherit" onClick={close}>閉じる</Button>
				<Button variant="contained" color="primary" onClick={showCroppedImage}>決定</Button>		
			</Box>
		</Dialog>
	);
};

export default ImageTrimming;
