import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//import Typography from '@mui/material/Typography';
import Title from './Title';
import RuleIcon from '@mui/icons-material/Rule';


interface RuleProps {

}

const Rule = (props:RuleProps)=> {
	return (
		<Container maxWidth="md">
			<Title variant="h2" text="ご利用上の注意" icon={<RuleIcon color="primary"/>}/>
			<Box style={{marginBottom:'2em'}}>
				<p>
					基本的には自由に使っていただいてＯＫなんですが、零細企業がほとんど趣味で運営しているサービスですので永遠に存在するというものではありません。大事なデータは別の場所にもきちんと保存するようにしてください。
				</p>
				<p>
					サーバーのメンテナンスや不調などでサービスが予告なく停止する場合もきっとあります。社運を賭けた大事な取引先との商談にこのサービスで猫を見せて心をつかもうなどという大冒険はオススメしません。
				</p>
				<p>また、トラブルを防ぐため以下の禁止事項は絶対にやらないようにお願いいたします。</p>
			</Box>
			<Paper style={{padding:'2em'}}>
				<Title variant="h4" text="禁止事項"/>
				・第三者の著作権を侵害する画像やテキストを使用してはいけません<br/>
				・誹謗中傷や差別を助長する表現など公の場所に不適切なテキストを登録してはいけません<br/>
				・動物愛護の観点から不適切と判断される行為や表現にこのサービスを利用してはいけません<br/>
				<Box style={{marginTop:'1em',fontSize:'80%',color:'#663333'}}>※違反した場合は問答無用で削除しますよ</Box>
			</Paper>
		</Container>
	);
}

export default Rule;
