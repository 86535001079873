import React,{useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CropIcon from '@mui/icons-material/Crop';
import {UserContainer} from './UserContainer';
import axios from "axios";
import Loading from './Loading';
import Toast from './Toast';
import InputField from './InputField';
import ImageSelect from './ImageSelect';
import YesNo from './YesNo';
import useStyles from './Styles';
import {Theme} from './Theme';
import {Inc,Cat,ImageData,MaxCatNameLength,MaxCatMemoLength,MaxCatTagLength} from './Inc';

interface EditCatProps {
	isOpen: boolean,
	catId: number,
	close(updated:boolean):void,
}

const EditCat = (props:EditCatProps)=> {
    const [states,setStates] = useState({
        image: {} as ImageData,
        isSend:false,
        isLoading:false,
		isUpdated:false,
		isDelete:false,
		cat:Inc.defaultCat(),
		newTag:"",
    });
	const [errors,setErrors] = useState(Inc.defaultCatError());
	const classes = useStyles();
    const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));
    const userState = UserContainer.useContainer();

	const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		props.close(false);
	}
	const openDelete = ()=>{
		setStates({...states,isDelete:true});
	}
	const closeDelete = ()=>{
		setStates({...states,isDelete:false});
	}
	const onChangeValue = (name:keyof Cat) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({...states,isUpdated:true,cat:{...states.cat,[name]:value}});
	};
	const onChangeCheckbox = (name:keyof Cat) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.checked;
		setStates({...states,isUpdated:true,cat:{...states.cat,[name]:value}});
	};
	const onChangeNewTag = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setStates({...states,isUpdated:true,newTag:value});
	};
	const setImage = (image:ImageData)=>{
		setStates({...states,isUpdated:true,image:image});
	}
	const addTag = ()=>{
		if( states.newTag==='' ){
			return;
		}
		setStates({...states,isUpdated:true,newTag:'',cat:{...states.cat,tags:states.cat.tags.concat([states.newTag])}});
	}
	const deleteTag = (index:number)=>{
		setStates({...states,isUpdated:true,newTag:'',cat:{...states.cat,tags:states.cat.tags.slice(0,index).concat(states.cat.tags.slice(index+1))}});
	}
	const validate = ()=>{
		if( !userState.user ){
			return;
		}

		let err:boolean = false;
		let error = Inc.defaultCatError();
		const name = states.cat.name.trim();
		if( name==="" ){
			error["name"] = "名前を入力してください";
			err = true;
		} else if( name.length>MaxCatNameLength ){
			error["name"] = "名前を"+MaxCatNameLength+"文字以内にしてください";
			err = true;
		}

		if(err){
			setToast('赤字の項目を修正してください');
			setErrors(error);
			return false;
		}
		return true;
	}

	const load = async (id:number)=>{
		if(!userState.user){
			return false;
		}
		try {
			await axios.get(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/cats/${id}`).then((response)=>{
				if( response.status===200 ){
					console.log(response.data);
					let image = {} as ImageData;
					if( response.data.img!=='' ){
						image = {
							name: response.data.img,
							source: '',
							size: 0,
							result: true,
						};
					}
					setStates({...states,image:image,cat:response.data,isLoading:false});
				}
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}		
	}
	const send = async ()=>{
		if(!userState.user){
			return false;
		}
		if( !validate() ){
			return false;
		}

		//let img = '';
		//if( states.image ){
		//	img = states.image.source;
		//}
		try {
			if( props.catId===0 ){
				await axios.post(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/cats`, {cat:states.cat,image:states.image}).then((response)=>{
					props.close(true);
				}).catch((e)=>{
					console.log(e);
				});
			} else {
				await axios.put(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/cats/${props.catId}`, {cat:states.cat,image:states.image}).then((response)=>{
					props.close(true);
				}).catch((e)=>{
					console.log(e);
				});
			}
		} catch(err) {
			console.log(err);
		}
	};
	const deleteCat = async ()=>{
		if(!userState.user){
			return false;
		}
		try {
			await axios.delete(`${process.env.REACT_APP_SYSTEM_URL}users/${userState.user.uid}/cats/${props.catId}`).then((response)=>{
				props.close(true);
			}).catch((e)=>{
				console.log(e);
			});
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(()=>{
		if(!props.isOpen){
			setStates({
				image: {} as ImageData,
				isSend:false,
				isLoading:false,
				isUpdated:false,
				isDelete:false,
				cat:Inc.defaultCat(),
				newTag:"",
			});
			setErrors(Inc.defaultCatError());
			return;
		}
		if( props.catId>0 ){
			setStates({...states,isLoading:true});
			load(props.catId);
		} else {
			setStates({...states,cat:Inc.defaultCat(),image: {} as ImageData,newTag:'',isLoading:false});
		}
	},[props.isOpen]);

	if( !props.isOpen ){
		return (null);
	}



    return (
		<>
			<Dialog
				open={props.isOpen}
				onClose={close}
				fullScreen={fullScreen}
				aria-labelledby="editcat-dialog-title"
				aria-describedby="editcat-dialog-description"	
			>
				<DialogTitle style={{marginTop:0,marginBottom:0,color:'#03a9f4',fontWeight:'bold',fontSize:'140%'}}>
					新しい猫を登録
				</DialogTitle>
				<DialogContent dividers={true}>
					<Box style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						・<span style={{color:'#03a9f4',fontWeight:'bold'}}>来た日</span>と<span style={{color:'#03a9f4',fontWeight:'bold'}}>誕生日</span>のどちらも空欄の場合は年表に表示されません<br/>
						・日付がはっきりしない時は<span style={{color:'#03a9f4',fontWeight:'bold'}}>だいたい</span>にチェックを入れておきましょう<br/>
						・<span style={{color:'#03a9f4',fontWeight:'bold'}}>イメージ画像</span>は左下の <CropIcon style={{fontSize:'100%',verticalAlign:'middle',backgroundColor:'black',color:'white',borderRadius:'0.2em'}}/> で正方形にトリミングすることができます<br/>
					</Box>				
					<Grid container spacing={1} style={{marginTop:'2em',marginBottom:"0.5em"}}>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6" style={{whiteSpace:'nowrap'}}>名前
							<span className={classes.formRequired}>必須</span>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
							<InputField label="名前" onChange={onChangeValue("name")} value={states.cat.name} error={errors.name} opt={{maxLength:MaxCatNameLength}}/>
							<FormHelperText>※{MaxCatNameLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6" style={{whiteSpace:'nowrap'}}>誕生日</Typography>
						</Grid>
						<Grid item xs={7} sm={6} md={5} style={{paddingBottom:'0.5em'}}>
							<TextField
								id="input-date"
								fullWidth={true}
								onChange = {onChangeValue("birth")}
								value={states.cat.birth}
								error={errors.birth!==""}
								helperText={errors.birth}
								type="date"
								variant="filled"
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									className:classes.formInput,
								}}
							/>
						</Grid>
						<Grid item xs={5} sm={3} md={3} style={{position:'relative'}}>
							<FormControlLabel control={<Checkbox size="small" color="secondary" onChange={onChangeCheckbox("almost_birth")} checked={states.cat.almost_birth} />} label="だいたい" style={{position:'absolute',bottom:'0.6em'}} />
						</Grid>

						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6" style={{whiteSpace:'nowrap'}}>来た日</Typography>
						</Grid>
						<Grid item xs={7} sm={6} md={5} style={{paddingBottom:'0.5em'}}>
							<TextField
								id="input-date"
								fullWidth={true}
								onChange = {onChangeValue("start")}
								value={states.cat.start}
								error={errors.start!==""}
								helperText={errors.start}
								type="date"
								variant="filled"
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									className:classes.formInput,
								}}
							/>
						</Grid>
						<Grid item xs={5} sm={3} md={3} style={{position:'relative'}}>
							<FormControlLabel control={<Checkbox size="small" color="secondary" onChange={onChangeCheckbox("almost_start")} checked={states.cat.almost_start} />} label="だいたい" style={{position:'absolute',bottom:'0.6em'}} />
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6" style={{whiteSpace:'nowrap'}}>旅立った日</Typography>
						</Grid>
						<Grid item xs={7} sm={6} md={5} style={{paddingBottom:'0.5em'}}>
							<TextField
								id="input-date"
								fullWidth={true}
								onChange = {onChangeValue("end")}
								value={states.cat.end}
								error={errors.end!==""}
								helperText={errors.end}
								type="date"
								variant="filled"
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									className:classes.formInput,
								}}
							/>
						</Grid>
						<Grid item xs={5} sm={3} md={3} style={{position:'relative'}}>
							<FormControlLabel control={<Checkbox size="small" color="secondary" onChange={onChangeCheckbox("almost_end")} checked={states.cat.almost_end} />} label="だいたい" style={{position:'absolute',bottom:'0.6em'}} />
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">タグ</Typography>
							<Box className={classes.formExplanation}>
								年表やリスト表示時にタグで絞り込むことができます
							</Box>
						</Grid>
						<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'1.5em'}}>
							<Box style={{marginBottom:'0.5em'}}>
								{states.cat.tags && states.cat.tags.map((tag:string,index:number)=>{
									const key = "tag"+index;
									return (
										<Chip label={tag} key={key} variant="outlined" onDelete={()=>deleteTag(index)}/>
									)
								})}
							</Box>
							<InputField label="新しいタグ" onChange={onChangeNewTag} value={states.newTag} error={errors.tags} opt={{maxLength:MaxCatTagLength,endAdornment:<IconButton size="small" onClick={addTag} disabled={errors.tags!==''}><AddCircleIcon/></IconButton>}}/>
							<FormHelperText>※{MaxCatTagLength}文字以内</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6">イメージ画像</Typography>
							<Box className={classes.formExplanation}>
								PNG / JPEG / GIF<br/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'1.5em'}}>
							<ImageSelect title="イメージ画像" message="ドラッグ＆ドロップ、又はタップしてファイルを選択" image={states.image} maxWidth={600} setImage={setImage}/>
						</Grid>
						<Grid item xs={12} sm={3} md={4}>
							<Typography variant="h6" style={{whiteSpace:'nowrap'}}>メモ
							</Typography>
						</Grid>
						<Grid item xs={12} sm={9} md={8} style={{paddingBottom:'0.5em'}}>
							<InputField label="メモ" onChange={onChangeValue("memo")} value={states.cat.memo} error={errors.memo} opt={{maxLength:MaxCatMemoLength,rows:8}}/>
							<FormHelperText>※{MaxCatMemoLength}文字以内</FormHelperText>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{states.cat.id>0 && <Button variant="outlined" color="error" onClick={openDelete}>削除</Button>}
					<Box style={{flex:1}}/>
					<Button variant="text" color="inherit" onClick={close}>閉じる</Button>
					<Button variant="contained" color="primary" onClick={send}>決定</Button>
				</DialogActions>
			</Dialog>
			<YesNo isOpen={states.isDelete} title={states.cat.name!=='' ? states.cat.name : '名無し'} text='削除してよろしいですか？' onYes={deleteCat} onNo={closeDelete}/>
            <Toast mes={toast} close={closeToast}/>
            <Loading isOpen={states.isLoading}/>
		</>
    );
}

export default EditCat;
