import React from 'react';
import Stack from '@mui/material/Stack';
//import Icon from '@mui/material/Icon';
//import BookIcon from '@mui/icons-material/Book';
import Typography from '@mui/material/Typography';

interface TitleProps {
	text: string,
	variant: 'h1'|'h2'|'h3'|'h4'|'h5',
	icon?: React.ReactNode,
}

const Title = (props:TitleProps)=> {

	return (
		<Stack direction="row" alignItems="center" gap={1}>
			{props.icon}
			{props.variant==='h1' && <Typography variant={props.variant} style={{fontSize:'140%',margin:'0em',padding:'0em',color:'#03a9f4'}}>{props.text}</Typography>}
			{props.variant==='h2' && <Typography variant={props.variant} style={{fontSize:'160%',marginBottom:'1em',paddingTop:'1em',color:'#03a9f4',fontWeight:'bold'}}>{props.text}</Typography>}
			{props.variant==='h3' && <Typography variant={props.variant} style={{fontSize:'140%',marginBottom:'0em',paddingTop:'1em',fontWeight:'bold'}}>{props.text}</Typography>}
			{props.variant==='h4' && <Typography variant={props.variant} style={{fontSize:'120%',marginBottom:'0.5em',paddingTop:'0em',fontWeight:'bold'}}>{props.text}</Typography>}
			{props.variant==='h5' && <Typography variant={props.variant} style={{fontSize:'100%',marginBottom:'1em',paddingTop:'1em',fontWeight:'bold'}}>{props.text}</Typography>}
		</Stack>
	);
}

export default Title;
