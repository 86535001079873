export const CookieHashName = "store10";
export const getCookieOptions = (domain:string | undefined)=>{
    //console.log("domain",domain);
    const expire = new Date();
    expire.setDate(expire.getDate() + 20);
    const options = {
        path: "/",
        expires: expire,
        maxAge: 86400*20,
        domain: domain,
        secure: false,
        httpOnly: false,
        sameSite: true,
    }
    return options;
};
export const MaxCatNameLength = 30;
export const MaxCatMemoLength = 500;
export const MaxCatTagLength = 10;
export const MaxProfileOwnerLength = 20;
export const MaxProfileTitleLength = 20;
export const MaxProfileMemoLength = 500;
export interface Dataset {
    id: string,
    label: string,    
}
export interface DataErrorset {
    id: string,
    label: string,
	error: boolean,
}
export interface User {
    id: number,
    name: string,
	mail: string,
    isLogined: boolean,
    status: string,
	status_label?:string,
}
export interface Cat {
	id: number,
	name: string,
	img: string,
	birth: string,
	start: string,
	end: string,
	almost_birth: boolean,
	almost_start: boolean,
	almost_end: boolean,
	tags: string[],
	memo: string,
}
export interface Article {
	id: number,
	appear: string,
	name: string,
	memo: string,
}
export interface Profile {
	code: string,
	title: string,
	owner: string,
	memo: string,
	status: boolean,
}
export interface CatError {
	id: string,
	name: string,
	img: string,
	birth: string,
	start: string,
	end: string,
	almost_start: string,
	almost_end: string,
	items: string,
	memo: string,
	tags: string,
}
export interface ProfileError {
	code: string,
	title: string,
	owner: string,
	memo: string,
	status: string,
}
export interface ImageData {
	name: string,
	source: string,
	size: number,
	result: boolean,
}



export class Inc {
    static dt = new Date();
    static today = Inc.dt.getFullYear() + "/" + (Inc.dt.getMonth()+1) + "/" + Inc.dt.getDate();
    static year = String(Inc.dt.getFullYear());
    static month = String(Inc.dt.getMonth()+1);
    static readonly defaultUser = ()=>{
        let user:User = {
            id: 0,
            name: "",
			mail: "",
            isLogined: false,
            status: "0",
        }
        return user;
    };
    static readonly defaultCat = ()=>{
        let cat:Cat = {
            id: 0,
            name: "",
			birth: "",
			start: "",
			end: "",
			almost_birth: false,
			almost_start: false,
			almost_end: false,
			img: "",
			memo: "",
			tags: [],
        }
        return cat;
    };
    static readonly defaultCatError = ()=>{
        let error:CatError = {
            id: "",
            name: "",
			birth: "",
			start: "",
			end: "",
			almost_start: "",
			almost_end: "",
			img: "",
			items: "",
			memo: "",
			tags: "",
        }
        return error;
    };
    static readonly defaultProfile = ()=>{
        let profile:Profile = {
            title: "",
			owner: "",
			code: "",
			memo: "",
			status: false
        }
        return profile;
    };
    static readonly defaultProfileError = ()=>{
        let error:ProfileError = {
            title: "",
			owner: "",
			code: "",
			memo: "",
			status: ""
        }
        return error;
    };
	static readonly getUploadImageURL = (imageKey:String)=>{
		if( imageKey!=="" && process.env.REACT_APP_IMAGE_VIEW_URL ){
			return process.env.REACT_APP_IMAGE_VIEW_URL+imageKey+".jpg";
		}
		return "";
	}

}