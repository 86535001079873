import React from 'react';
import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';

import Title from './Title';
import imgStore from './img/store.png';
import imgR7 from './img/r7.png';
import img403 from './img/403.png';

interface AboutProps {

}

const About = (props:AboutProps)=> {
	return (
		<Container maxWidth="md">
			<Title variant="h2" text="Nekonenって何？" icon={<InfoIcon color="primary"/>}/>
			<Box style={{marginBottom:'3em'}}>
				<p>飼い猫の年表を簡単に作成するためのサービスです。</p>
				<p>猫との付き合いが長くなってくると「ハルサメさん（猫の名前です）がうちに来た時、うな茶（もちろん猫の名前です）はまだ元気だったんだっけ？」みたいな素朴な疑問にしばらく考えこんでしまうことが増えてきましたので必要に迫られて作りました。</p>
				<p>猫以外のために使ってみても構いません。動物で無くても良いと思います。</p>
			</Box>
			<Title variant="h2" text="運営会社"/>
			<Box style={{marginBottom:'3em'}}>
			<p>Nekonenは有限会社レトロインクが運営しています。<br/>
			<a href="https://www.retro-ink.com/" target="_blank" rel="noopener noreferrer" style={{color:'teal'}}>https://www.retro-ink.com/</a></p>
			<p>有限会社レトロインクはウェブアプリケーションの開発会社です。<br/>
			デジタルデータ販売に特化したプラットフォームサービスの他、雑貨百般を取り扱うオンラインストアや保険用のレセプト発行システム、販売会社用の業務日報ツール、営業管理ツールなどを開発しています。</p>
			</Box>
			<List
				component="nav"
			>
				<ListItemButton component={Link} to="https://store.retro-biz.com/" target="_blank">
					<ListItemAvatar><Avatar alt="架空ストア" src={imgStore}/></ListItemAvatar>
					<ListItemText primary="架空ストア" secondary="雑貨百般・本当に売ってる架空のお店"/>
				</ListItemButton>
				<ListItemButton component={Link} to="https://403adamski.jp/" target="_blank">
					<ListItemAvatar><Avatar alt="403adamski" src={img403}/></ListItemAvatar>
					<ListItemText primary="403adamski" secondary="年表作成者向けの規約"/>
				</ListItemButton>
				<ListItemButton component={Link} to="https://r7ticket.jp/" target="_blank">
					<ListItemAvatar><Avatar alt="R7 TICKET SERVICE" src={imgR7}/></ListItemAvatar>
					<ListItemText primary="R7 TICKET SERVICE" secondary="当日精算専用チケット予約サービス"/>
				</ListItemButton>
			</List>			
		</Container>
	);
}

export default About;
