import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import FireplaceIcon from '@mui/icons-material/Fireplace';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
//import PublicIcon from '@mui/icons-material/Public';
//import PublicOffIcon from '@mui/icons-material/PublicOff';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import InfoIcon from '@mui/icons-material/Info';
import PetsIcon from '@mui/icons-material/Pets';
import RuleIcon from '@mui/icons-material/Rule';
import SecurityIcon from '@mui/icons-material/Security';
import {UserContainer} from './UserContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCat } from "@fortawesome/free-solid-svg-icons";
import Title from './Title';
import imgStore from './img/store.png';
import imgR7 from './img/r7.png';
import img403 from './img/403.png';

interface HeaderProps {
	openLogin():void,
	openConfig():void,
	openEditCat(id:number):void,
}

const Header = (props:HeaderProps)=> {
	const userState = UserContainer.useContainer();
	const [isOpen,setIsOpen] = useState(false);
	const openLogin = ()=>{
		props.openLogin();
	}
	const add = ()=>{
		props.openEditCat(0);
	}
	const config = ()=>{
		props.openConfig();
	}
	const openDrawer = ()=>{
		setIsOpen(true);
	}
	const closeDrawer = ()=>{
		setIsOpen(false);
	}
	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if ( event && event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' ||
			(event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		if( !open ){
			setIsOpen(open);
		}
    };

	return (
		<>
		<AppBar position="static" style={{backgroundColor:'white',boxShadow:'none',borderTop:'12px solid #03a9f4'}}>
			<Toolbar variant="dense" style={{paddingRight:'0.2em'}}>
				<IconButton edge="start" color="primary" aria-label="menu" sx={{ mr: 2, marginRight: 0 }} onClick={openDrawer}>
					<FontAwesomeIcon icon={faShieldCat} style={{fontSize:'140%'}}/>
				</IconButton>
				<Title variant="h1" text="Nekonen"/>
				<Box sx={{ flexGrow: 1 }} />
				<Box>
					{userState.user && <IconButton color="primary" onClick={add}><AddIcon style={{fontSize:'140%'}}/></IconButton>}
					{userState.user && userState.profile.status===true && <IconButton color="primary" onClick={config}><BrightnessHighIcon style={{fontSize:'140%'}}/></IconButton>}
					{userState.user && userState.profile.status===false && <IconButton color="primary" onClick={config}><Brightness4Icon style={{fontSize:'140%'}}/></IconButton>}
					<IconButton color="primary" onClick={openLogin}><AccountCircleIcon style={{fontSize:'140%'}}/></IconButton>
				</Box>
			</Toolbar>
		</AppBar>
		<SwipeableDrawer
			anchor="left"
			open={isOpen}
			onClose={toggleDrawer(false)}
			onOpen={toggleDrawer(true)}
		>
			<List
				component="nav"
				subheader={
					<ListSubheader component="div" id="nested-list-subheader" style={{backgroundColor:'#EEEEEE',position:'relative'}}>
						<FontAwesomeIcon icon={faShieldCat} style={{fontSize:'120%',marginRight:'0.5em'}}/>
						{'Nekonen Menu'}
						<IconButton style={{position:'absolute',top:'0.2em',right:'0.2em'}} onClick={closeDrawer}><ChevronLeftIcon/></IconButton>
					</ListSubheader>
				}
				style={{marginBottom:'2em'}}
			>
				<ListItemButton component={Link} to="/" onClick={closeDrawer}>
					<ListItemIcon><PetsIcon/></ListItemIcon>
					<ListItemText primary="ワークスペース" secondary="登録したり確認したり"/>
				</ListItemButton>
				<ListItemButton component={Link} to="/about" onClick={closeDrawer}>
					<ListItemIcon><InfoIcon/></ListItemIcon>
					<ListItemText primary="このコンテンツは何？" secondary="知らなくてもいい概要と運営会社"/>
				</ListItemButton>
				<ListItemButton component={Link} to="/rule" onClick={closeDrawer}>
					<ListItemIcon><RuleIcon/></ListItemIcon>
					<ListItemText primary="ご利用上の注意" secondary="年表を作成する前に念のため"/>
				</ListItemButton>
				<ListItemButton component={Link} to="/privacy" onClick={closeDrawer}>
					<ListItemIcon><SecurityIcon/></ListItemIcon>
					<ListItemText primary="個人情報保護方針" secondary="ご安全に"/>
				</ListItemButton>
			</List>
			<List
				component="nav"
				subheader={
					<ListSubheader component="div" id="nested-list-subheader" style={{backgroundColor:'#EEEEEE'}}>
					姉妹サービス
					</ListSubheader>
				}
			>
				<ListItemButton component={Link} to="https://store.retro-biz.com/" target="_blank">
					<ListItemAvatar><Avatar alt="架空ストア" src={imgStore}/></ListItemAvatar>
					<ListItemText primary="架空ストア" secondary="雑貨百般・本当に売ってる架空のお店"/>
				</ListItemButton>
				<ListItemButton component={Link} to="https://403adamski.jp/" target="_blank">
					<ListItemAvatar><Avatar alt="403adamski" src={img403}/></ListItemAvatar>
					<ListItemText primary="403adamski" secondary="年表作成者向けの規約"/>
				</ListItemButton>
				<ListItemButton component={Link} to="https://r7ticket.jp/" target="_blank">
					<ListItemAvatar><Avatar alt="R7 TICKET SERVICE" src={imgR7}/></ListItemAvatar>
					<ListItemText primary="R7 TICKET SERVICE" secondary="当日精算専用チケット予約サービス"/>
				</ListItemButton>
			</List>
		</SwipeableDrawer>				
		</>
	);
}

export default Header;
