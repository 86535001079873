import React, { useState } from "react";
import {useDropzone} from 'react-dropzone';
import imageCompression from "browser-image-compression";
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/Image';
import CancelIcon from '@mui/icons-material/Cancel';
import CropIcon from '@mui/icons-material/Crop';
import useStyles from './Styles';
import LoadImage from './LoadImage';
import {ImageData,Inc} from './Inc';
import ImageTrimming from './ImageTrimming';

interface ImageUploadProps {
	title: string,
	message?: string,
	error?:boolean,
	image: ImageData;
	maxSize?: number,
	maxWidth?: number,
	setImage: (result:ImageData) => void;
	componentRef?: (instance: HTMLInputElement | null) => void;
}

const ImageSelect: React.FC<ImageUploadProps> =  (props)=> {
	const classes = useStyles();
	const defaultStates = {
		isLoading: false,
		isYesNo: false,
		isTest: false,
		deleted: false,
		isTrimming:false,
		error: "",
		currentIndex: 0,
	};
	const [states,setStates] = useState(defaultStates);

	const file2string = async (file:File,func:any)=>{
		//console.log(file);

		const reader = new FileReader()
		let maxSize = 3;
		let maxWidth = 800;

		if( props.maxSize ){
			maxSize = props.maxSize;
		}
		if( props.maxWidth ){
			maxWidth = props.maxWidth;
		}

		let size = 0;
		let res = {} as ImageData;
		reader.onabort = () => {
			console.log('file reading was aborted');
			res.result = false;
			return res;
		}
		reader.onerror = () => {
			console.log('file reading has failed');
			res.result = false;
			return res;
		}
		reader.onload = () => {
			//console.log("d");
			const base64Str = reader.result
			if( base64Str ){
				//setStates({...states,isFileTypeError:false,isLoading:false});
				//props.setImage(base64Str.toString(),size);				
				//console.log("e");
				res.name = "";
				res.source = base64Str.toString();
				res.size = size;
				res.result = true;
				func(res);
			}
		}
		const compressOptions = {
			maxSizeMB: maxSize,
			maxWidthOrHeight: maxWidth,
			fileType: "image/jpeg",
		};
		//console.log("b");
		const compressedFile = await imageCompression(file, compressOptions);
		//console.log("c1");
		size = compressedFile.size;
		reader.readAsDataURL(compressedFile);
		//reader.readAsDataURL(file);
	}

	const removeImage = ()=>{
		//resetErrors();
		setStates({...states,error:'',isYesNo:false,deleted:true});

		props.setImage({} as ImageData);
	}

	const accepted = async ()=>{
		console.log("accepted");
	}
	const rejected = ()=>{
		console.log("rejected");
	}
    const onDrop = async (acceptedFiles:File[]) => {
        //console.log('acceptedFiles:', acceptedFiles);
		if(acceptedFiles.length===0){
			setStates({...states,isLoading:false,error:'nofile'});
			return;
		}
		
		setStates({...states,isLoading:true,error:''});
		let error = "";
		let image = props.image;

		for( let i=0; i<acceptedFiles.length; i++ ){
			if( acceptedFiles[i] ){
				if (
					[
						"image/gif",
						"image/jpeg",
						"image/png",
					].includes(acceptedFiles[i].type)
				) {
					await file2string(acceptedFiles[i],(result:ImageData)=>{
						image = result;
						props.setImage(image);
					});
				} else {
					error = "wrongtype";
				}
			}
		}
		setStates({...states,isLoading:false,error:error});
    };

	const updateImage = (src:string)=>{
		const image = {
			name: "",
			source: src,
			size: 0,
			result: true,
		} as ImageData;
		setStates({...states,isTrimming:false})
		props.setImage(image);
	}
	const openTrimming = ()=>{
		setStates({...states,isTrimming:true});
	}
	const closeTrimming = ()=>{
		setStates({...states,isTrimming:false});
	}


	let cl = classes.formDropzone;
	if( props.error ){
		cl = cl + " " + classes.formDropzoneError;
	}

	const { getRootProps, getInputProps, open } = useDropzone({noClick:true,onDrop:onDrop,maxFiles:1,multiple:false,onDropAccepted:accepted,onDropRejected:rejected});

	if( states.isLoading ){
		return <Box className={cl} style={{padding:"4em 0",textAlign:"center"}}><CircularProgress /></Box>
	}

	const errorMessage:{[key:string]:string;} = {
		'nofile':'アップロードできるファイルがありません',
		'wrongtype':'PNG / JPEG / GIF 以外のファイル形式は利用できません',
		'maxfiles':'ファイルは1個までです',
	};

  	return (
		<React.Fragment>
			<input {...getInputProps()}/>
			{props.image.result ? (
				<div {...getRootProps()} style={{position:'relative'}}>
					<Box style={{width:'300px',height:'300px',position:'relative'}}>
					<LoadImage
						src={props.image.source!=='' ? props.image.source : Inc.getUploadImageURL(props.image.name)}
						width={300}
						height={300}
					/>
					<IconButton onClick={removeImage} style={{position:'absolute',top:'0',right:'0'}}><CancelIcon className={classes.formImageCancelButton}/></IconButton>
					<IconButton onClick={openTrimming} style={{position:'absolute',bottom:'0',left:'0'}}><CropIcon className={classes.formImageCropButton}/></IconButton>
					{!props.image.result &&
					<div style={{position:'absolute',bottom:'0.5em',padding:'0'}}>
						<IconButton onClick={open}><UploadFileIcon style={{fontSize:'120%',color:'#000000',backgroundColor:'#FFFFFF',opacity:0.8}}/></IconButton>
					</div>
					}
					</Box>
				</div>
			):(
			<div {...getRootProps()} className={cl}>
				<FormLabel className={classes.formLabel+' '+classes.formInner}>{props.title}</FormLabel>
				<p style={{textAlign:'center',padding:'1em 0 0 0'}}>
					<IconButton onClick={open}><UploadFileIcon style={{fontSize:'280%',color:"#999999"}}/></IconButton>
				</p>
				{props.message && props.message!=="" &&
				<p style={{textAlign:'center',fontSize:'80%',padding:'0em 1em 1.5em 1em',color:'#666666'}}>
					{props.message}
				</p>
				}
			</div>

			)}
			{states.error!=='' && (
				<p style={{color:"#FF0000"}}>※{errorMessage[states.error]}</p>
			)}
			{props.image.result && <ImageTrimming width={300} height={300} isOpen={states.isTrimming} src={props.image.source!=='' ? props.image.source : Inc.getUploadImageURL(props.image.name)} update={updateImage} close={closeTrimming}/>}
		</React.Fragment>
	);
};

export default ImageSelect;
