import React, { useLayoutEffect, useState } from 'react';

const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
		//console.log("update size");
		//console.log(size);
		//console.log([window.innerWidth, window.innerHeight]);
		if( size[0]!==window.innerWidth || size[1]!==window.innerHeight ){
    		setSize([window.innerWidth, window.innerHeight]);
		}
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export default useWindowSize;