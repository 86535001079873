import React,{useState} from 'react';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CakeIcon from '@mui/icons-material/Cake';
import PetsIcon from '@mui/icons-material/Pets';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import BottomMenus from './BottomMenu';
import {Inc,Cat} from './Inc';

interface CatListProps {
	cats: Cat[],
	tags: string[],
	onClick():void,
	edit(id:number):void,
}

const CatList = (props:CatListProps)=> {
	const [states,setStates] = useState({
		isTag: false,
		selectedTag: '',
	})

	const edit = (index:number)=>{
		props.edit(props.cats[index].id);
	}

	const openTag = ()=>{
		setStates({...states, isTag:true});
	}

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if ( event && event.type === 'keydown' &&
		  ((event as React.KeyboardEvent).key === 'Tab' ||
		  (event as React.KeyboardEvent).key === 'Shift')
		) {
		  return;
		}
		if( !open ){
			setStates({...states, isTag:false});
		}
	};
  
	const selectTag = (tag:string)=>{
		if( states.selectedTag===tag ){
			tag = '';
		}
		setStates({...states,selectedTag:tag});
	}
  

	return (
		<>
		<List style={{paddingBottom:'8em'}}>
		{props.cats.map((cat:Cat,index:number)=>{
			const key = "cat"+index;
			if( states.selectedTag!=='' ){
				if(! cat.tags.includes(states.selectedTag) ){
					return (null);
				}
			}

			return (
				<ListItemButton key={key} disableGutters onClick={()=>edit(index)} divider={true}>
					<ListItemIcon>
					{cat.img!=='' ? (
						<Avatar src={Inc.getUploadImageURL(cat.img)}/>
					):(
						<Avatar><FontAwesomeIcon icon={faPaw}/></Avatar>
					)}
					</ListItemIcon>
					<ListItemText
						primary={cat.name}
						secondary={(
							<>
								<Typography component="span" sx={{display:{xs:'block',sm:'inline'},marginRight:{xs:'0',sm:'1em'},verticalAlign:'middle',fontSize:'100%'}}>
									<PetsIcon style={{fontSize:'130%',color:'#b3e5fc',verticalAlign:'middle',marginRight:'0.25em'}} />
									<span style={{verticalAlign:'middle'}}>
										{cat.start!=='' ? cat.start : '？'}
										{cat.almost_start && '頃'}
										{cat.end!=='' ? '～'+cat.end : ''}
										{cat.end!=='' && cat.almost_end && '頃'}
									</span>
								</Typography>
								<Typography component="span" sx={{display:{xs:'block',sm:'inline'},verticalAlign:'middle',fontSize:'100%'}}>
									<CakeIcon style={{fontSize:'130%',color:'#b2ebf2',verticalAlign:'middle',marginRight:'0.25em'}}/>
									<span style={{verticalAlign:'middle'}}>
										{cat.birth!=='' ? cat.birth : '？'}
										{cat.birth!=='' && cat.almost_birth && '頃'}
									</span>
								</Typography>
							</>
						)}
					/>
					<ListItemSecondaryAction>
						<ArrowForwardIcon color='primary'/>
					</ListItemSecondaryAction>
				</ListItemButton>
			)
		})}
		</List>
		<BottomMenus mode="list" start="start" zoom={100} toggleMode={props.onClick} openTag={openTag} selectedTag={states.selectedTag}/>
		<SwipeableDrawer
			anchor="bottom"
			open={states.isTag}
			onClose={toggleDrawer(false)}
			onOpen={toggleDrawer(true)}
		>
			<Container maxWidth="md">
				<Box style={{padding:'3em 0em',textAlign:'center'}}>
					{props.tags.map((tag:string,index:number)=>{
						const key = "tag"+index;
						return (
							<Button variant={states.selectedTag===tag ? "contained":"outlined"} color={states.selectedTag===tag ? "primary":"inherit"} key={key} style={{marginRight:'0.5em'}} onClick={()=>selectTag(tag)}>{tag}</Button>
						)
					})}
					{props.tags.length===0 && <Box>タグは設定されていません</Box>}
				</Box>
			</Container>
		</SwipeableDrawer>				

		</>
	);
}

export default CatList;
