import React from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

interface Props {
    close():void,
    latest:string,
    isOpen:boolean,
}

const Oldversion:React.FC<Props> = (props)=> {
	
    if( !props.isOpen ){
        return (null);
    }

	const close = ()=>{

    }

	return (
		<Dialog
			open={props.isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={false}
			onClose={close}
		>
			<DialogTitle color='primary'>最新バージョンにアップデートして下さい</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} style={{marginBottom:"1em"}}>
					<Grid item xs={12} sm={6}><div className="verBox">
						<h3>ご利用中のバージョン</h3>
						<div className="current">
							{process.env.REACT_APP_VERSION}
						</div>
					</div></Grid>
					<Grid item xs={12} sm={6}><div className="verBox">
						<h3>最新バージョン</h3>
						<div className="latest">
							{props.latest}
						</div>
					</div></Grid>
				</Grid>
				<div className="verGuide">
					<h3>スマホ（iOS/Android）の場合</h3>
					<div>
						「画面を上から下へスワイプして放す」を何度か行うことで更新されます。
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>アプリの場合</h3>
					<div>
						「ホーム画面に追加」してアプリとして起動している場合は、アプリを終了してからもう一度起動すると最新バージョンが読み込まれます。
						<p>
						※２～３回繰り返さないと更新されない場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>Windowsの場合</h3>
					<div>
						強制リロード（Ctrl+F5）で最新バージョンが読み込まれます。<br/>
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>Macの場合</h3>
					<div>
						強制リロード（command+R 又は command+Shift+R）で最新バージョンが読み込まれます。<br/>
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
			</DialogContent>
        </Dialog>

    );
}

export default Oldversion;
