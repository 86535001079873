import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './Styles';

interface Props {
    isOpen:boolean,
}

const NoService:React.FC<Props> = (props)=> {
	const classes = useStyles();
    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{

    }

	return (
		<Dialog
			open={props.isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown={false}
			onClose={close}
		>
			<DialogTitle color='primary'>休止中</DialogTitle>
			<DialogContent style={{paddingBottom:'3em'}}>
				メンテナンスのためサービスを一時休止しています。<br/>
			</DialogContent>					
        </Dialog>
    );
}

export default NoService;
